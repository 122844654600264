import React from 'react'
import { navigate, Router } from '@reach/router'
import { Link } from 'gatsby'
import Login, { signIn } from '../components/Login'
import Layout from "../components/Layout"
import accountStyles from "../styles/pages/account.module.scss"
import { Container, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'

const Home = () => {
  return (
    <section>            
        <Container maxWidth="lg">
          <div className={accountStyles.gridWrapper}>
            <Grid 
              container spacing={3} 
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={12} md={6}>                
                <h1>You're All Set</h1>
                <p>Thanks for registering. We will send you an email when we launch.</p>
              </Grid>
              <Grid item xs={12} md={6}>
                
              </Grid>        
            </Grid>
          </div>      
        </Container>
    </section>
  )
}

const Preferences = () => {
  return (
    <section>            
        <Container maxWidth="lg">
          <div className={accountStyles.gridWrapper}>
            <Grid 
              container spacing={3} 
              direction="row"
              justify="center"
              alignItems="center">
              <Grid item xs={12} md={6}>                
                <h1>Preferences</h1>
                <p>This area is not currently available. Check back soon.</p>
              </Grid>
              <Grid item xs={12} md={6}>
                
              </Grid>        
            </Grid>
          </div>      
        </Container>
    </section>
  )
}

const isAuthenticated = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('isAuthenticated') === 'true';
  } else {
    return false;
  }
};

class Account extends React.Component {    
  constructor(props) {
    super(props);

    this.state = {user: false};
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    const token = await signIn.authClient.tokenManager.get('idToken');
    if (token) {
      this.setState({user: token.claims.name});
    } else {
      // Token has expired
      this.setState({user: false});
      localStorage.setItem('isAuthenticated', 'false');
    }
  }

  logout() {
    signIn.authClient.signOut().catch((error) => {
      console.error('Sign out error: ' + error)
    }).then(() => {
      localStorage.setItem('isAuthenticated', 'false');
      this.setState({user: false});
      navigate('/');
    });
  }
  
  render() {        
    if (!isAuthenticated()) {
      return (
        <Login/>
      );
    }
    
    return (
        <Layout page="account" bgColor="inherit">
            <section>
            
                <Container maxWidth="lg">
                  <div className={[accountStyles.gridWrapper, accountStyles.accountHeader].join(' ')}>
                    <Grid 
                      container spacing={3} 
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <Grid item xs={12}>
                        <React.Fragment>
                          <h6>Welcome, {this.state.user}.</h6>     
                          <Button variant="outlined" color="secondary" onClick={this.logout}>Logout</Button>                
                        </React.Fragment>

                        {/* <nav>                          
                          <Link to="/account">My Account</Link>{' '}
                          <Link to="/account/preferences">Preferences</Link>{' '}
                        </nav> */}

                      </Grid>
                          
                    </Grid>
                  </div>      
                </Container>

            </section>
            <Router>
                <Home path="/account"/>
                <Preferences path="/account/preferences"/>                
            </Router>
        </Layout>
    )
  }
}

export default Account